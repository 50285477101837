<template>
	<div class="classify">
		<frame :top1="top1" :top2="top2">
			<lists :titles='titles' :flag='flag' :contentC='datas' @add='refresh()' @del='refresh()'>
				<div class="title">
				<!-- 	<div class="listcont">
						数据列表
					</div> -->
					<div class="add">
						<el-button type="warning" style="background-color: red;color: white;" plain @click='addClassify()'>添加</el-button>
					</div>
				</div>
			</lists>
		</frame>
	</div>
</template>

<script>
	import frame from '../public/Frame.vue';
	import lists from './componClass/ClassifyList.vue';
	export default {
		name: 'classify',
		data() {
			return {
				top1: '4-3',
				top2: ['4'],
				titles: ['分类名称', '级别', '缩略图','排序', '设置', '操作'],
				flag: 1,
				datas: null,
				tabbar: ['全部商品', '已上架', '未上架', '待审核', '未通过'],
			}
		},
		components: {
			frame,
			lists
		},
		created() {
			
		},
		methods:{
			addClassify(){
				this.$router.push({path:'/addClassify'},function(){},function(){})
			}
		}
	}
</script>

<style scoped="scoped">
	.title{
		width: 88%;
		display: flex;
		justify-content: space-between;
		margin: 0 auto;
		height: 50px;
		line-height: 50px;
	}
	.add{
		height: 50px;
		margin-left: -15px;
	}
</style>
