<template>
	<div class="listClassify">
		<Card style='margin-left: 200px'>
			<div style="height: 600px;">
				<div class="titles">商品分类</div>
				<div class="title">
					<slot></slot>
				</div>
				<div class="title" v-if="admin_type==1">
				<div class="selname" >商家类型:</div>
				<Select placeholder="请选择" class="sel">
				<Option  :value="item.storeid" v-for='item,index in selects' :key='index' @click.native="shopType(item.storeid)">{{item.s_name}}</Option>
				 </Select>
				</div>	
				<div class="content">
					<div id="contents">
						<div class="cont">
					
							<table>
								<tr>
									<td class='titNa' v-for="item,index in titles" :key='index'>{{item}}</td>
								</tr>
							</table>
							<div class="contlist">
								<Collapse v-model="value1" v-for='item,index in listData' :key='item.gtid'>
									<Panel :name="index">
										<span class="conTit con1">{{item.t_name}}</span>
										<span class="conTit con2">{{item.level}}</span>
										<span class="conTit con3"><img v-if="item.tlogo" :src="uploadingUrl + item.tlogo" ></span>
										<span class="conTit con4"><Icon type="md-arrow-round-up" /></span>
										<span class="conTit con5">设置</span>
										<span class="conTit con6">
											<span class='blue' @click="compile(item.gtid)">编辑</span>
											&nbsp;
											<span class='blue' @click="del(item.gtid)">删除</span>
										</span>
										<p slot="content" v-for="item2,index2 in item.son" :key='item2.gtid'>
											<span class="conTit cons1">{{item2.t_name}}</span>
											<span class="conTit cons2">{{item2.level}}</span>
											<span class="conTit cons3"><img v-if="item2.tlogo" :src="uploadingUrl + item2.tlogo" ></span>
											<span class="conTit cons4"><Icon type="md-arrow-round-up" @click='top(item2.gtid)'/></span>
											<span class="conTit cons5">
												<span class='blue' @click="add()">新增下级</span>
												&nbsp;
												<!-- <span class='blue'>查看商品</span> -->
											</span>
											<span class="conTit cons6">
												<span class='blue' @click="compile(item2.gtid)">编辑</span>
												&nbsp;
												<span class='blue' @click="del(item2.gtid)">删除</span>
											</span>
										</p>
									</Panel>
								</Collapse>
							</div>
						</div>
					</div>
				</div>
				<!-- <page :num='num'></page> -->
			</div>
		</Card>
	</div>
</template>

<script>
	import page from '../componList/Page.vue';
	export default {
		name: 'listClassify',
		props: {
			contentC: {
				type: Array,
			},
			titles: {
				type: Array,
			},
			flag: {
				type: Number,
			}
		},
		data() {
			return {
				social: [],
				dialogVisible: false,
				value1: '1',
				listData: [],
				uploadingUrl: '',
				num: 100,
				selects:''
			}
		},
		created() {
			this.uploadingUrl = this.$request.imgUrl2()+'uploads/';
			let token = this.$storage.getLocal('token');
			// this.$request.typeListData({token,storeid:''}).then(res=>{
			// 	this.listData = res.data.data
			// })
			// let token = this.$storage.getLocal('token');
			var admin_type=this.$storage.getLocal('type')
			this.admin_type=admin_type
			// if(admin_type=='1')
			// {
				this.$request.selGoodsStoreName({token,type:1}).then(res=>{
					if(res.code == 0){ 
						console.log(res)
						this.selects = res.data;
					}
				})
			// }
		},
		components: {
			page
		},
		methods: {
			top(id){
				this.$confirm('是否提升此分类?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then((res) => {
					let token = this.$storage.getLocal('token');
					this.$request.upLevelStatus({token,id}).then(res=>{
						if (res.code == 0) {
							this.$notify({
								title: '成功',
								message: '提升成功',
								type: 'success'
							});
							this.$request.typeListData({token,storeid:''}).then(res=>{
								this.listData = res.data.data
							})
						} else {
							this.$notify({
								title: '失败',
								message: '提升失败',
								type: 'warning'
							});
						}
					})
				}).catch(() => {
					this.$message({
						type: 'info',
						message: '已取消提升'
					});
				});
			},
			add(){
				this.$router.push({path:'/addClassify'},function(){},function(){})
			},
			shopType(value)
			{
				console.log(value)
				let token = this.$storage.getLocal('token');
				this.$request.typeListData({token,storeid:value}).then(res=>{
					this.listData = res.data.data
				})
			},
			start(id, start) {
				let token = this.$storage.getLocal('token');
				this.$request.goodsIsGrounding({
					token,
					id,
					grounding: start == 2 ? 1 : 2,
				}).then(res => {
					if (res.code == 0) {
						this.$notify({
							title: '成功',
							message: '成功',
							type: 'success'
						});
						this.$emit("del", 'ok');
					} else {
						this.$notify({
							title: '警告',
							message: '失败',
							type: 'warning'
						})
					}
				})
			},
			del(id) { //删除
				this.$confirm('此操作将永久删除该用户,不可恢复,是否继续?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then((res) => {
					let token = this.$storage.getLocal('token');
					this.$request.delTypeData({
						id,
						token
					}).then(res => {
						if (res.code == 0) {
							this.$notify({
								title: '成功',
								message: '删除成功',
								type: 'success'
							});
							this.$request.typeListData({token,storeid:''}).then(res=>{
								this.listData = res.data.data
							})
						} else {
							this.$notify({
								title: '失败',
								message: '删除失败',
								type: 'warning'
							});
						}
					})
				}).catch(() => {
					this.$message({
						type: 'info',
						message: '已取消删除'
					});
				});
			},
			examine(id) { //查看
				this.dialogVisible = true
			},
			log(id) { //日志
				console.log(id)
			},
			compile(id) { //编辑
				this.$router.push({path:'/addClassify?id='+id},function(){},function(){})
			}
		}
	}
</script>

<style scoped="scoped">
	.blue{
		color: #42B983;
		cursor: pointer;
	}
	.conTit{
		display: inline-block;
		/* width: 19.4%; */
		text-align: center;
	}
	.con1{
		width: 23%;
	}
	
	.con2{
		width: 14.6%;
	}
	.con3{
		width: 15.5%;
	}
	.con4,.con5{
		width: 15%;
	}
	.con6{
		width: 13%;
	}
	.con4,.cons4{
		font-size: 30px;
	}
	.cons4{
		color: #42B983;
	}
	
	
	
	
	
	
	.cons1{
		width: 25%;
		/* height: 100px; */
		line-height: 100px;
	}
	.cons2,.cons3,.cons4,.cons5{
		width: 16.2%;
		line-height: 100px;
	}
	.cons3 img{
		line-height: 100px;
		height: 55px;
	}
	.con3{
		height: 30px;
	}
	.con3 img{
		height: 100%;
	}
	.contlist{
		text-align: left;
		font-size: 16px;
	}
	table {
		width: 100%;
		background: #eeeeee;
	}

	td {
		background-color: #FFFFFF;
		font-size: 15px;
		height: 30px;
		/* width: 15%; */
	}

	tr .titNa {
		background-color: #F9FAFC;
		font-size: 15px;
		font-weight: 800;
	}

	.oper {
		display: flex;
		justify-content: space-around;
		color: #42B983;
	}
	.title
		{
			display: flex;
			width: 20%;
			margin-bottom: 10px;
			/* margin-left: -0%; */
		}
		.selname
		{
			width: 200px;
			display: flex;
			margin-top: 5px;
		}
		.sel
		{
			margin-left: -50px;
		}

</style>
